<script setup lang="ts">
import NavBar from "./partials/dashboard/NavBar.vue";
import DesktopSideBar from "./partials/dashboard/DesktopSideBar.vue";
import MobileSideBar from "./partials/dashboard/MobileSideBar.vue";
import OnboardingStepperModal from "./partials/dashboard/onboarding/OnboardingStepperModal.vue";
import ShareJobModal from "@/components/dashboard/jobs/ShareJobModal.vue";
import ContactModals from "@/components/dashboard/support/ContactModals.vue";
import { useChatStore } from "@/store/chat";
import { useApplicationStore } from "@/store/application";
import { useJobStore } from "@/store/job";

useSeoMeta({
  title: "Dashboard",
  ogTitle: "Dashboard - Hirable",
  description: "Hirable employer dashboard",
  ogDescription: "Hirable employer dashboard",
});

const user = useSupabaseUser();
const chatStore = useChatStore();
const applicationStore = useApplicationStore();
const jobStore = useJobStore();
await useLazyAsyncData("get-chats-and-apps", async () => {
  await Promise.all([applicationStore.getApplications(), chatStore.getChats(user.value!.id)]);
});

const sidebarOpen = ref(false);

const { totalUnreadMessages } = storeToRefs(chatStore);
const { totalUnreadApplications } = storeToRefs(applicationStore);
const { shareJobModal } = storeToRefs(jobStore);

const navigation = computed(() => [
  { name: "Dashboard", href: ROUTES.dashboardHome, icon: "fa6-solid:house" },
  { name: "Jobs", href: ROUTES.dashboardJobs, icon: "fa6-solid:briefcase" },
  {
    name: "Applications",
    href: ROUTES.dashboardApplications,
    icon: "fa6-solid:rectangle-list",
    notification: totalUnreadApplications.value,
  },
  { name: "Candidates", href: ROUTES.dashboardCandidates, icon: "fa6-solid:clipboard-user" },
  { name: "Chat", href: ROUTES.dashboardChat, icon: "fa6-solid:message", notification: totalUnreadMessages.value },
  { name: "Archived Jobs", href: ROUTES.dashboardArchivedJobs, icon: "fa6-solid:box-archive" },
  { name: "Payments", href: ROUTES.dashboardPayments, icon: "material-symbols:credit-card" },
]);

const subNavigation = computed(() => [{ name: "Settings", href: ROUTES.dashboardAccountSettings, icon: "fa6-solid:gear" }]);

const toggleSideBar = (value: boolean) => {
  sidebarOpen.value = value;
};
</script>

<template>
  <div>
    <MobileSideBar
      :navigation="navigation"
      :sub-navigation="subNavigation"
      :sidebar-open="sidebarOpen"
      @toggle-mobile-side="(val: boolean) => toggleSideBar(val)"
    />
    <DesktopSideBar :navigation="navigation" :sub-navigation="subNavigation" />
    <div class="xl:pl-72 bg-white-gray dark:bg-dark-gray min-h-screen h-full">
      <NavBar @toggle-mobile-side="(val) => toggleSideBar(val)" />
      <main class="py-4 sm:py-6">
        <div class="px-4 sm:px-6">
          <slot />
        </div>
      </main>
    </div>
    <OnboardingStepperModal />
    <ShareJobModal />
    <ContactModals />
  </div>
</template>
