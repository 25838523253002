<script setup lang="ts">
import { useJobStore } from "@/store/job";

interface Emits {
  (e: "close"): void;
}

const emit = defineEmits<Emits>();
const config = useRuntimeConfig();
const jobStore = useJobStore();
const { shareJobModal } = storeToRefs(jobStore);

const linkCopied = ref(false);

const appLink = computed(() => `${config.public.appUrl}${ROUTES.action}?type=job&id=${shareJobModal.value.job?.id}`);

const socialLinks = computed(() => [
  {
    name: "LinkedIn",
    icon: "f7:logo-linkedin",
    shareUrl: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(appLink.value)}`,
  },
  {
    name: "Twitter",
    icon: "fa6-brands:square-x-twitter",
    shareUrl: `https://twitter.com/intent/tweet?url=${encodeURIComponent(appLink.value)}&text=${encodeURIComponent(
      `Check out this job: ${shareJobModal.value.job?.title}`
    )}`,
  },
  {
    name: "Facebook",
    icon: "f7:logo-facebook",
    shareUrl: `https://www.facebook.com/dialog/share?app_id=${config.public.metaHirableAppId}&display=popup&href=${encodeURIComponent(
      appLink.value
    )}&redirect_uri=${encodeURIComponent(appLink.value)}`,
  },
]);

const copyLink = () => {
  navigator.clipboard.writeText(appLink.value);
  linkCopied.value = true;
  setTimeout(() => {
    linkCopied.value = false;
  }, 1000);
};

const closeShareJobModal = () => {
  toggleShareJobModal({ active: false });
  emit("close");
};

const shareToSocial = (url: string) => {
  navigateTo(url, {
    external: true,
    open: {
      target: "_blank",
    },
  });
};
</script>

<template>
  <DialogModal v-if="shareJobModal.job" :show="shareJobModal.active" @close="closeShareJobModal">
    <template #title> {{ shareJobModal.title }} </template>
    <template #content>
      <div class="mb-6 flex flex-col gap-y-6" v-if="shareJobModal.showInvite">
        <p>
          Invite matching candidates to apply for <span class="font-medium">{{ shareJobModal.job.title }}</span
          >.
        </p>
        <div class="w-full flex justify-center">
          <PrimaryButton @click="inviteCandidates(shareJobModal.job.id)" class="w-fit"> Invite Candidates </PrimaryButton>
        </div>

        <div class="flex items-center gap-4">
          <div class="h-[1px] flex-1 bg-light-gray"></div>
          <div class="text-medium-gray">and share via</div>
          <div class="h-[1px] flex-1 bg-light-gray"></div>
        </div>
      </div>

      <div class="flex justify-center items-center gap-3">
        <Icon
          v-for="link in socialLinks"
          :key="link.name"
          :name="link.icon"
          @click="shareToSocial(link.shareUrl)"
          class="w-8 h-8 rounded-md hover:text-primary text-dark-gray cursor-pointer"
        />
      </div>
      <div class="flex items-center gap-x-2 mt-6">
        <p class="w-full p-2 border border-light-gray rounded-md shadow-sm overflow-ellipsis overflow-hidden text-nowrap">{{ appLink }}</p>
        <div
          class="flex flex-shrink-0 items-center justify-center shadow-sm cursor-pointer w-[38px] h-[38px] rounded-md border border-light-gray hover:bg-white-gray"
        >
          <Icon @click="copyLink" :name="linkCopied ? 'fa6-solid:check' : 'tabler:copy'" class="w-4 h-4 text-medium-gray" />
        </div>
      </div>
    </template>
  </DialogModal>
</template>
